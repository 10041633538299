// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Index_wrapper__yGzVJ {\n  display: flex;\n  width: 100vw;\n  height: 100vh;\n}\n\n@media (max-width: 1024px) {\n  .Index_wrapper__yGzVJ {\n    display: none;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/Index/Index.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,aAAA;AACJ;;AACA;EACI;IACI,aAAA;EAEN;AACF","sourcesContent":[".wrapper {\n    display: flex;\n    width: 100vw;\n    height: 100vh;\n}\n@media (max-width: 1024px) {\n    .wrapper {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Index_wrapper__yGzVJ"
};
export default ___CSS_LOADER_EXPORT___;
