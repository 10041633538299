// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Timer_timer__CHRWp {\n  color: #fff;\n  text-align: center;\n  font-size: 80px;\n  font-style: normal;\n  font-weight: 800;\n  line-height: 100%; /* 80px */\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.Timer_timer__CHRWp .Timer_m__NnFCq {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  width: 110px;\n}\n.Timer_timer__CHRWp .Timer_s__Fic-D {\n  display: flex;\n  align-items: center;\n  text-align: center;\n  justify-content: center;\n  margin: 0px 4px;\n}\n.Timer_timer__CHRWp .Timer_ms__NYHrd {\n  display: flex;\n  width: 105px;\n}", "",{"version":3,"sources":["webpack://./src/shared/Timer/Timer.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,YAAA;AACJ;AACE;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,uBAAA;EACA,eAAA;AACJ;AACE;EACE,aAAA;EACA,YAAA;AACJ","sourcesContent":[".timer {\n  color: #fff;\n  text-align: center;\n  font-size: 80px;\n  font-style: normal;\n  font-weight: 800;\n  line-height: 100%; /* 80px */\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  .m {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    width: 110px;\n  }\n  .s {\n    display: flex;\n    align-items: center;\n    text-align: center;\n    justify-content: center;\n    margin: 0px 4px;\n  }\n  .ms {\n    display: flex;\n    width: 105px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timer": "Timer_timer__CHRWp",
	"m": "Timer_m__NnFCq",
	"s": "Timer_s__Fic-D",
	"ms": "Timer_ms__NYHrd"
};
export default ___CSS_LOADER_EXPORT___;
