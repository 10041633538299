// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CloseFullWindow_closeFull__zxOx\\+ {\n  display: none;\n  width: 70px;\n  height: 70px;\n}\n.CloseFullWindow_closeFull__zxOx\\+ svg, .CloseFullWindow_closeFull__zxOx\\+ img {\n  width: 100%;\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://./src/widgets/Player/components/CloseFullWindow/CloseFullWindow.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EACA,YAAA;AACJ;AAAI;EACI,WAAA;EACA,YAAA;AAER","sourcesContent":[".closeFull {\n    display: none;\n    width: 70px;\n    height: 70px;\n    svg, img {\n        width: 100%;\n        height: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"closeFull": "CloseFullWindow_closeFull__zxOx+"
};
export default ___CSS_LOADER_EXPORT___;
