// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Player_wrapper__L\\+x1l {\n  position: relative;\n  width: calc(100% - 360px);\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://./src/widgets/Player/Player.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,yBAAA;EACA,YAAA;AACJ","sourcesContent":[".wrapper {\n    position: relative;\n    width: calc(100% - 360px);\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Player_wrapper__L+x1l"
};
export default ___CSS_LOADER_EXPORT___;
