import { FC, useEffect } from "react";
import s from "./RenderLoader.module.scss";
import { Timer } from "../../../shared/Timer/Timer";
import { renderScene } from "../../../utils/functions/playcanvas/renderScene";
import { useDispatch } from "react-redux";
import { changeShowModal, setRenderHash } from "../../../redux/actions";
import { popUp } from "../../../features/popUp/PopUp";
import { handleImage } from "../../api/renderAndLoadImage";
import store from "../../../redux/store";
type RenderLoaderT = {
  closeFunc: any;
};
export const RenderLoader: FC<RenderLoaderT> = ({ ...props }) => {
  const { closeFunc } = props;
  const dispatch = useDispatch();
  async function rendered() {
    const data = await renderScene(closeFunc);
    //@ts-ignore
    const stopwatch = window.stopwatch;
    if (data.status === "success" && stopwatch) {
      // handleImage({ base64Image: data.imageBase64, type: "preview" });
      dispatch(
        changeShowModal({
          nameModal: "renderPreview",
          stateModal: true,
          otherParams: { img: data.imageBase64 },
        })
      );
      setTimeout(() => {
        popUp({ type: "success", message: "Image uploaded successfully." });
      });

      // stopwatch.reset();
      // stopwatch.stop();
      dispatch(
        changeShowModal({
          nameModal: "renderLoader",
          stateModal: false,
          otherParams: { img: data.imageBase64 },
        })
      );
    }
    if (data.status === "pending") {
      dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));

      setTimeout(() => {
        popUp({ type: "success", message: "Image uploading ..." });
      }, 1500);
      dispatch(setRenderHash({ hash: data.configHash }));
    }
  }
  useEffect(() => {
    rendered();
  }, []);
  function stopRender() {
    dispatch(setRenderHash({ hash: "" }));
    dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));

    //@ts-ignore
    const stopwatch = window.stopwatch;
    // stopwatch.reset();
    // stopwatch.stop();
    //@ts-ignore
    window.stopwatch = undefined;

    closeFunc();
  }
  return (
    <div className={s.wrapper}>
      <Timer />
      <div className={s.title}>One moment...</div>
      <div className={s.subtitle}>Placing cool stuff takes time</div>
      <div className={s.hint}>
        <div className={s.hintInfo}>
          <div className={s.hintTitle}>
            Decided to change something in the configuration?
          </div>
          <div className={s.hintSubtitle}>
            You can stop the rendering and make all the necessary changes
          </div>
        </div>
        <div className={s.hintStop} onClick={stopRender}>
          Stop rendering
        </div>
      </div>
    </div>
  );
};
