// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Interface_wrapper__3PlOg {\n  position: relative;\n  background: #fff;\n  width: 360px;\n  padding-bottom: 60px;\n}\n\n.Interface_title__LEdH0 {\n  color: #17172e;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n}\n\n.Interface_titleWrapper__yhhs0 {\n  padding: 12px;\n  border-bottom: 1px solid #dfdfdf;\n  display: flex;\n  width: -webkit-fill-available;\n  justify-content: space-between;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/widgets/Interface/Interface.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,oBAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,gCAAA;EACA,aAAA;EACA,6BAAA;EACA,8BAAA;EACA,mBAAA;AACF","sourcesContent":[".wrapper {\n  position: relative;\n  background: #fff;\n  width: 360px;\n  padding-bottom: 60px;\n}\n\n.title {\n  color: #17172e;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n}\n\n.titleWrapper {\n  padding: 12px;\n  border-bottom: 1px solid #dfdfdf;\n  display: flex;\n  width: -webkit-fill-available;\n  justify-content: space-between;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Interface_wrapper__3PlOg",
	"title": "Interface_title__LEdH0",
	"titleWrapper": "Interface_titleWrapper__yhhs0"
};
export default ___CSS_LOADER_EXPORT___;
