// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MobileHint_wrapper__fVxmW {\n  display: none;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 92%;\n  height: 100vh;\n  background: #fff;\n  padding: 0px 4%;\n}\n\n.MobileHint_title__Rus81 {\n  color: #17172e;\n  text-align: center;\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 110%; /* 26.4px */\n  margin-top: 20px;\n  margin-bottom: 16px;\n}\n\n.MobileHint_subtitle__Zb4M7 {\n  color: #17172e;\n  text-align: center;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 130%; /* 23.4px */\n}\n\n@media (max-width: 1024px) {\n  .MobileHint_wrapper__fVxmW {\n    display: flex;\n  }\n}", "",{"version":3,"sources":["webpack://./src/shared/MobileHint/MobileHint.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;EACA,aAAA;EACA,gBAAA;EACA,eAAA;AACF;;AACA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;EACA,gBAAA;EACA,mBAAA;AAEF;;AAAA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;AAGF;;AADA;EACE;IACE,aAAA;EAIF;AACF","sourcesContent":[".wrapper {\n  display: none;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: calc(100% - 8%);\n  height: 100vh;\n  background: #fff;\n  padding: 0px 4%;\n}\n.title {\n  color: #17172e;\n  text-align: center;\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 110%; /* 26.4px */\n  margin-top: 20px;\n  margin-bottom: 16px;\n}\n.subtitle {\n  color: #17172e;\n  text-align: center;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 130%; /* 23.4px */\n}\n@media (max-width: 1024px) {\n  .wrapper {\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "MobileHint_wrapper__fVxmW",
	"title": "MobileHint_title__Rus81",
	"subtitle": "MobileHint_subtitle__Zb4M7"
};
export default ___CSS_LOADER_EXPORT___;
