// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ComponentNavigator_wrapper__nla3h {\n  padding: 0px 12px;\n  overflow-y: auto;\n  max-height: calc(100vh - 120px);\n}\n\n.ComponentNavigator_title__pIe7u {\n  color: #7d7d8a;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 130%; /* 15.6px */\n  margin-top: 16px;\n}", "",{"version":3,"sources":["webpack://./src/widgets/ComponentNavigator/ComponentNavigator.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gBAAA;EACA,+BAAA;AACF;;AACA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;EACA,gBAAA;AAEF","sourcesContent":[".wrapper {\n  padding: 0px 12px;\n  overflow-y: auto;\n  max-height: calc(100vh - 120px);\n}\n.title {\n  color: #7d7d8a;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 130%; /* 15.6px */\n  margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ComponentNavigator_wrapper__nla3h",
	"title": "ComponentNavigator_title__pIe7u"
};
export default ___CSS_LOADER_EXPORT___;
