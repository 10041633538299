// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Search_wrapper__3DpVg {\n  width: 180px;\n  position: relative;\n  margin-right: 8px;\n}\n.Search_wrapper__3DpVg input {\n  height: 18px;\n  width: -webkit-fill-available;\n  border-radius: 10px;\n  border: 1px solid #ced4da;\n  background: #fff;\n  padding: 8px;\n  padding-left: 38px;\n  outline: none;\n  color: #17172e;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n.Search_wrapper__3DpVg label {\n  display: flex;\n  cursor: pointer;\n  position: absolute;\n  left: 8px;\n  top: 50%;\n  transform: translateY(-50%);\n}", "",{"version":3,"sources":["webpack://./src/shared/Search/Search.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EACA,iBAAA;AACF;AAAE;EACE,YAAA;EACA,6BAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAEJ;AAAE;EACE,aAAA;EACA,eAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,2BAAA;AAEJ","sourcesContent":[".wrapper {\n  width: 180px;\n  position: relative;\n  margin-right: 8px;\n  input {\n    height: 18px;\n    width: -webkit-fill-available;\n    border-radius: 10px;\n    border: 1px solid #ced4da;\n    background: #fff;\n    padding: 8px;\n    padding-left: 38px;\n    outline: none;\n    color: #17172e;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n  }\n  label {\n    display: flex;\n    cursor: pointer;\n    position: absolute;\n    left: 8px;\n    top: 50%;\n    transform: translateY(-50%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Search_wrapper__3DpVg"
};
export default ___CSS_LOADER_EXPORT___;
