import axios, { AxiosError } from "axios";
import { apiData } from ".";
import { popUp } from "../../features/popUp/PopUp";
import store from "../../redux/store";
import { genDataSceneToSend } from "../../utils/functions/genDataSceneToSend";
import { getDataStaticEnvironment } from "../../utils/functions/getDataStaticEnvironment";
type handleImageT = {
  base64Image: string;
  fileName?: string;
  type: "download" | "preview";
};

function loadImageAndConvertToBase64(url: any) {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
}

export function handleImage({
  base64Image,
  fileName = "image.jpg",
  type = "download",
}: handleImageT) {
  // Преобразуем base64 изображение в Blob
  // const byteCharacters = atob(base64Image);
  // const byteNumbers = new Array(byteCharacters.length);
  // for (let i = 0; i < byteCharacters.length; i++) {
  //   byteNumbers[i] = byteCharacters.charCodeAt(i);
  // }
  // const byteArray = new Uint8Array(byteNumbers);
  // const blob = new Blob([byteArray], { type: "image/jpeg" });

  if (type === "download") {
    // Создаем ссылку для скачивания
    // const url = window.URL.createObjectURL(blob);

    // Создаем элемент <a> для скачивания
    const link = document.createElement("a");
    link.href = base64Image;
    link.download = fileName;

    // Скрываем элемент <a>
    link.style.display = "none";

    // Добавляем элемент <a> на страницу
    document.body.appendChild(link);

    // Эмулируем клик на элементе <a> для начала скачивания
    link.click();

    // Очищаем ссылку после скачивания
    window.URL.revokeObjectURL(base64Image);

    // Удаляем элемент <a> после скачивания
    document.body.removeChild(link);
  } else if (type === "preview") {
    // openImageInNewTab(base64Image);
    loadImageAndConvertToBase64(base64Image)
      .then(function (base64ImageData) {
        var newWindow: any = window.open();
        newWindow.document.write(
          '<html><body style="margin: 0 auto; overflow: hidden; width: max-content">'
        );
        newWindow.document.write(
          '<img style="width: auto; height: 100vh" src="' +
            base64ImageData +
            '">'
        );
        newWindow.document.write("</body></html>");
      })
      .catch(function (error) {
        console.error("Произошла ошибка:", error);
      });

    // window.open(base64Image, "_blank");
  }
}

export class ApiData {
  // baseURL = "https://vivid-temp-render.3dconfiguration.com/3d";
  // token: any = "zCxGZgV3iVUR93C2Cua2lTgZZZppRL1z32VifmzwdPgcP";

  // baseURL = "https://renderapi-3d.vivid3d.tech/";
  // adminURL = "https://renderadmin.vivid3d.tech";
  // token = "zCxGZgV3iVUR93C2Cua2lTgZZZppRL1z32VifmzwdPgcP";

  // baseURL = "https://vivid-temp-render.3dconfiguration.com/3d/";
  // adminURL = "https://renderadmin-dev.vivid3d.tech";
  baseURL =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEVELOP
      : process.env.REACT_APP_API_URL;
  adminURL =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_ADMIN_API_URL_DEVELOP
      : process.env.REACT_APP_ADMIN_API_URL;
  token = "zCxGZgV3iVUR93C2Cua2lTgZZZppRL1z32VifmzwdPgcP";
}

export class RenderApi extends ApiData {
  public async renderAndLoadImage(body: any, closeFunc: any): Promise<any> {
    const result = genDataSceneToSend(body);
    const environments = getDataStaticEnvironment();
    result.environment = environments;
    console.log("result: ", result);
    //@ts-ignore
    const stopwatch = window.stopwatch;
    try {
      const { data } = await axios.post(
        `${this.baseURL}renderConfiguration`,
        result,
        { headers: { Authorization: `${this.token}` } }
      );
      // const filename = `${apiData.baseURL}/${data.filename}`;

      if (stopwatch) {
        // downloadImageAutomatically(filename);
        // stopwatch.stop();

        // closeFunc();
        return data;
      }

      return data;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        if (stopwatch) {
          // stopwatch.reset();
          // stopwatch.stop();
          setTimeout(() => {
            //@ts-ignore
            popUp({ type: "failure", message: err.message });
            closeFunc();
          }, 500);
        }
        if (err.response) {
          return err.response.data;
        } else {
          return err.message;
        }
      }

      return "Error";
    }
  }
}
