// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlayCanvasIntegration_wrapper__AKks1 {\n  position: relative;\n  width: 100%;\n  overflow: hidden;\n  height: 100%;\n}\n\n.PlayCanvasIntegration_add__0GbUK {\n  position: absolute;\n  top: 20px;\n  left: 20px;\n  background: #ccc;\n  border-radius: 6px;\n  padding: 12px 18px;\n  cursor: pointer;\n  font-size: 24px;\n}", "",{"version":3,"sources":["webpack://./src/widgets/Player/components/PlayCanvasIntegration/PlayCanvasIntegration.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;AACJ;;AACA;EACI,kBAAA;EACA,SAAA;EACA,UAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;AAEJ","sourcesContent":[".wrapper {\n    position: relative;\n    width: 100%;\n    overflow: hidden;\n    height: 100%;\n}\n.add {\n    position: absolute;\n    top: 20px;\n    left: 20px;\n    background: #ccc;\n    border-radius: 6px;\n    padding: 12px 18px;\n    cursor: pointer;\n    font-size: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "PlayCanvasIntegration_wrapper__AKks1",
	"add": "PlayCanvasIntegration_add__0GbUK"
};
export default ___CSS_LOADER_EXPORT___;
