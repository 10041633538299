// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Checkbox_checkbox__Mgj0w {\n  position: relative;\n  width: 44px;\n  height: 22px;\n  border-radius: 12px;\n  background: #ced4da;\n  cursor: pointer;\n}\n.Checkbox_checkbox__Mgj0w .Checkbox_point__ktnqs {\n  transition: all 0.2s;\n  position: absolute;\n  left: 2px;\n  top: 50%;\n  transform: translateY(-50%);\n  border-radius: 50%;\n  width: 18px;\n  height: 18px;\n  background: #fff;\n}\n.Checkbox_checkbox__Mgj0w.Checkbox_active__36RUH .Checkbox_point__ktnqs {\n  left: calc(100% - 20px);\n  background: #37cc8f;\n}", "",{"version":3,"sources":["webpack://./src/shared/Checkbox/Checkbox.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;AACF;AACE;EACE,oBAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,2BAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AACJ;AAGI;EACE,uBAAA;EACA,mBAAA;AADN","sourcesContent":[".checkbox {\n  position: relative;\n  width: 44px;\n  height: 22px;\n  border-radius: 12px;\n  background: #ced4da;\n  cursor: pointer;\n\n  .point {\n    transition: all 0.2s;\n    position: absolute;\n    left: 2px;\n    top: 50%;\n    transform: translateY(-50%);\n    border-radius: 50%;\n    width: 18px;\n    height: 18px;\n    background: #fff;\n  }\n\n  &.active {\n    .point {\n      left: calc(100% - 20px);\n      background: #37cc8f;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": "Checkbox_checkbox__Mgj0w",
	"point": "Checkbox_point__ktnqs",
	"active": "Checkbox_active__36RUH"
};
export default ___CSS_LOADER_EXPORT___;
