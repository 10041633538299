// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loader_wrapper__xU85x {\n  position: fixed;\n  width: 100%;\n  max-width: 300px;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  box-sizing: border-box;\n  height: auto;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  grid-auto-columns: auto;\n  text-align: center;\n}\n\n.Loader_title__Ljg0L {\n  color: #37cc8f;\n  text-align: center;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 130%; /* 23.4px */\n  margin-top: 30px;\n  margin-bottom: 6px;\n}\n\n.Loader_subtitle__M6snq {\n  color: #fff;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 130%;\n}", "",{"version":3,"sources":["webpack://./src/processes/modals/Loader/Loader.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,gBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,sBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,uBAAA;EACA,kBAAA;AACF;;AAEA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACF","sourcesContent":[".wrapper {\n  position: fixed;\n  width: 100%;\n  max-width: 300px;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  box-sizing: border-box;\n  height: auto;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  grid-auto-columns: auto;\n  text-align: center;\n}\n\n.title {\n  color: #37cc8f;\n  text-align: center;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 130%; /* 23.4px */\n  margin-top: 30px;\n  margin-bottom: 6px;\n}\n\n.subtitle {\n  color: #fff;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 130%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Loader_wrapper__xU85x",
	"title": "Loader_title__Ljg0L",
	"subtitle": "Loader_subtitle__M6snq"
};
export default ___CSS_LOADER_EXPORT___;
